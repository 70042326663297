<script setup lang="ts">
import {
  HelpFooter,
  CurvedHeader
} from 'temper-component-library'

const route = useRoute()
</script>

<template>
  <NuxtLayout name="base">
    <div
      class="base"
    >
      <CurvedHeader variant="green" />
      <div class="inner-page">
        <slot />
      </div>
      <HelpFooter v-if="route.path.includes('dashboard')" />
      <div
        v-else-if="route.path.includes('started')"
        class="help-footer-extended"
      />
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as *;
@use '@/assets/scss/breakpoints' as *;
@use '@/assets/scss/sizes' as *;

.base {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: $neutralGrey50;
  min-height: calc(100vh - 56px);
  padding: env(safe-area-inset-bottom, $doubleOff);
  @include tab {
    padding: env(safe-area-inset-bottom, $none);
  }

  &.onTop {
    z-index: 105;
  }

  .dashboard-header {
    background: $green900;
    width: 100%;
    height: 329px;
    z-index: 1;
    @include tab {
      height: 441px;
      border-radius: 0 0 0 120px;
    }
  }

  .inner-page {
    padding: 80px $triple $none;
    width: 100%;
    margin: $none auto;
    margin-top: -273px !important;
    z-index: 2;
    min-height: calc(100vh - 56px);

    @include sm {
      padding: $quadruple;
    }
    @include md {
      padding: $sixfold;
    }
    @include tab {
      margin-top: -377px;
      min-height: calc(100vh - 64px);
      padding: 88px $triple $none;
    }
    @include lg {
      align-items: center;
      padding: $none $none 120px;
      width: 1090px;
    }
    @include xl {
      padding: $none $none 120px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .help-footer-extended {
    @include tab {
      position: absolute;
      background: $purple100;
      height: 251px;
      width: 100%;
      border-radius: 120px 120px $none $none;
      bottom: 0;
      z-index: 1;
    }
  }
}

.text-only :deep(.btn-outline) {
  border: none !important
}

.modal-title {
  text-align: center !important;
  margin-bottom: $singleOff;
}

.modal-body-intro {
  text-align: center;
  margin-top: $singleOff;
  color: $grey800;
  margin-bottom: $doubleOff;
}
</style>
